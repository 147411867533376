<template>
  <div class="contact-page">
    <div class="max-width mt-14">
      <v-row>
        <v-col cols="12" md="6" >
          <span class="primary--text font-35 font-500">{{
            $t("GetInTouch")
          }}</span>
          <p class="black--text font-25 font-300 mt-2 mb-5">
            {{ $t("contactDesc") }}
          </p>

          <v-form>
            <!-- name -->
            <base-input
              class="c-btn"
              :placeholder="$t('yourName')"
            ></base-input>

            <!-- email -->
            <base-input
              class="my-8 c-btn"
              :placeholder="$t('yourEmail')"
            ></base-input>

            <!-- message -->
            <base-textarea
              class="c-btn"
              :rows="5"
              :placeholder="$t('yourMessage')"
            ></base-textarea>

            <v-btn
              class="c-btn mt-6"
              color="primary"
              block
              height="40"
              ><span class="white--text font-300 font-20">{{
                $t("send")
              }}</span></v-btn
            >
          </v-form>
        </v-col>
        <v-col cols="12" md="6" class="text-center">
          <v-img
            class="mx-auto mb-5"
            max-width="450px"
            max-height="450px"
            :src="require('@/assets/images/home/header-img.png')"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    socials: [
      {
        icon: require("@/assets/images/icons/phone-light.png"),
        text: "+971 54 995 9160",
        link: "",
      },
      {
        icon: require("@/assets/images/icons/email-light.png"),
        text: "Info@anapog.com",
        link: "",
      },
      {
        icon: require("@/assets/images/global/facebook.png"),
        text: "Anapog",
        link: "",
      },
      {
        icon: require("@/assets/images/global/insta.png"),
        text: "Anapog",
        link: "",
      },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.contact-page {
  .max-width {
    max-width: 1200px;
  }
}
</style>
